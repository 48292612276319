import React from 'react'

export default function Hero() {
    return (
        <>
            <section id="iq-home" className="iq-banner overview-block-pt iq-bg iq-bg-fixed iq-over-blue-90">
                <div className="container">
                    <div className="banner-text mt-2 mb-5">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-5 col-md-12">
                                <img className="img-fluid" src="assets/img/likeme_hero_section_img_new.png" alt="" />
                            </div>
                            <div className="col-lg-7 col-md-12 align-self-center">
                                <h2 className="text-white  iq-mb-10"><span className="iq-font-yellow">Join the Social Media Evolution,Claim Free LMC</span></h2>
                                <p className="text-white ">LikeME Coin (LMC) is the native token to LikeME, an open & decentralized social media platform. Participate in LikeME Airdrop to claim free LMC tokens!</p>
                                <a className="button mt-3" href="https://gleam.io/jO93V/likeme-airdrop" target='_blank'>
                                    <span className="button-inner-box">
                                        <span className="icon-one icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                        <span className="text-btn">Join Airdrop</span>
                                        <span className="icon-two icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
