import React from 'react'

export default function Footer() {
    return (
        <>
            <div>
                <footer className="iq-footer-2">
                    <div className="footer-top iq-bg iq-bg-fixed overview-block-pt iq-pb-40">
                        <div className="container">
                            <div className="row gap-lg-0 gap-4 justify-content-center">
                                <div className="col-lg-12 col-md-12 iq-contact text-center">
                                    <div className="logo iq-mt-0 iq-mb-20 d-flex justify-content-center"><img id="logo_img" className="img-fluid" src="assets/img/likeme_white_svg.svg" alt="#" /> </div>
                                    <ul className="iq-media-blog iq-mt-30 ">
                                        <li><a href="https://x.com/LikeMeCoin"><i className="fa fa-twitter" /></a></li>
                                        <li><a href="https://www.facebook.com/people/LikeMe-Coin/61557800742474/?mibextid=ZbWKwL" target='_blank'><i className="fa fa-facebook" /></a></li>
                                        <li><a href="https://www.instagram.com/likemecoin" target='_blank'><i className="fa fa-instagram" /></a></li>
                                        <li><a href="https://t.me/likemecoin_en"><i className="fa fa-telegram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 iq-mtb-20">
                                    <div className="iq-copyright iq-mt-10 text-center">Copyright <span id="copyright"> </span> <a href="javascript:void(0)"><span className="iq-font-yellow">LikeME</span> </a> All Rights Reserved </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div id="back-to-top">
                    <a className="top" id="top" href="#top"> <i className="ion-ios-upload-outline" /> </a>
                </div>
            </div>

        </>
    )
}
