import React from 'react'

export default function About() {
    return (
        <>
            <section className="overview-block-pt device-blog pt-0 pb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-12">
                            <h2 className="iq-font-black">
                                <span className="iq-font-yellow">LikeME Coin (LMC) Airdrop
                                </span>
                            </h2>
                            <p className="iq-mt-25">LikeME is giving away free LMC tokens to users as part of its community expansion. We aim to onboard over a million users and this Airdrop is a kick-start to this journey. </p>
                            <div className="calculator iq-mt-25">
                            </div>
                            <a className="button mt-3 iq-mb-100" href="https://gleam.io/jO93V/likeme-airdrop">
                                <span className="button-inner-box">
                                    <span className="icon-one icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                    <span className="text-btn">
                                        Join Airdrop
                                    </span>
                                    <span className="icon-two icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                </span>
                            </a>
                        </div>
                        <div className="col-lg-5 col-md-12">
                           <img className="img-fluid" alt="image" src="assets/img/airdrop_image.png" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
