import React, { useState, useContext, useEffect } from 'react'
import CountdownTimer from './CountdownTimer'
import { errors } from 'ethers';
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function Statistics({ generateElements }) {
    const { showmenu, setshowmenu, formatAddress,
        copyaddress,
        web3,
        account,
        balance,
        rate,
        library,
        smuser,
        toastSuccess,
        toastError,
        getuserDB,
        getsmcs,
        esigner,
        ethers,
        dbuser,
        settings,
        stakcontract,
        MemebersData,
        formatDateTime,
        Members,
    } = useContext(ProjectContext)
    const [amount, setamount] = useState(10);
    const [show, setShow] = useState(false);
    const [trdone, settrdone] = useState(false);
    const [email, setemail] = useState("");
    const [emailSend, setemailSend] = useState(0);
    const [tandc, settandc] = useState(false);
    const [Otp, setOtp] = useState("");
    const [hash, sethash] = useState("");
    // const [stakcontract, setstakcontract] = useState(null);
    const [rewardtbl, setrewardtbl] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const onDeposit = async () => {
        if (!tandc) {
            toastError("Accept terms and conditions");
            settrdone(false);
            return;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            toastError("Invalid email address");
            settrdone(false);
            return;
        }

        //code to verify email is valid or not

        settrdone(true);
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "email",
                    submethod: "insertemail",
                    key: process.env.REACT_APP_KEY,
                    eml: email,
                    addr: account,
                })
                .then((res) => {
                    setemailSend(1);
                    getuserDB()
                    if (res.data.error) {
                        toastError(res.data.error)
                        return "";
                    }
                    toastSuccess("OTP sent to your email")
                });
            settrdone(false);
            // navigate("/dashboard");
        } catch (error) {
            console.log("Error", error);
            toastError(error);
            settrdone(false);
            return;
        }
    }

    const checkOtp = async () => {
        settrdone(true);
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "email",
                    submethod: "verifyemail",
                    key: process.env.REACT_APP_KEY,
                    eml: email,
                    addr: account,
                    otp: Otp,
                })
                .then((res) => {
                    getuserDB();
                    if (res.data.error) {
                        toastError(res.data.error)
                        return "";
                    }
                    toastSuccess(res.data.data)
                    setTimeout(() => {
                        setShow(false);
                    }, 2000);
                });
            settrdone(false);
            // navigate("/dashboard");

        } catch (error) {
            console.log("Error", error);
            toastError(error);
            settrdone(false);
            return;
        }
    }
    const editEmail = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "email",
                    submethod: "edit",
                    key: process.env.REACT_APP_KEY,
                    addr: account
                })
                .then((res) => {
                    getuserDB();
                    if (res.data.error) {
                        toastError(res.data.error)
                        return "";

                    }
                    toastSuccess(res.data.data)
                });

        } catch (error) {
            console.log("Error", error);
            toastError(error);
            settrdone(false);
            return;
        }
    }
    const resendEmail = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "email",
                    submethod: "update",
                    key: process.env.REACT_APP_KEY,
                    addr: account
                })
                .then((res) => {
                    getuserDB();
                    if (res.data.error) {
                        toastError(res.data.error)
                        return "";
                    }
                    toastSuccess(res.data.data)
                });

        } catch (error) {
            toastError(error);
            settrdone(false);
            return;
        }
    }
    const stake = async () => {
        settrdone(true);
        try {
            var stk = await stakcontract.stake();

            const receipt = await stk.wait();
            sethash(receipt.transactionHash);
            // sethash(randomHash);
            getsmcs()
            generateElements()
            toastSuccess("Transaction done");
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "email",
                    submethod: "stake",
                    key: process.env.REACT_APP_KEY,
                    addr: account,
                    hash: receipt.transactionHash,
                    // hash: randomHash,
                    amount: amount,
                })
                .then((res) => {
                    getuserDB();
                    // var dedata = decryptData(res.data);
                    if (res.data.error) {
                        return "";
                    }
                });

            settrdone(false);

        } catch (error) {
            console.log("Error", error);
            settrdone(false);
            const regex = /"message":"([^"]*)"/g;
            const matches = [...error.message.matchAll(regex)];
            const secondMessage = matches.length >= 2 ? matches[1][1] : null;
            const modifiedErrorMessage = secondMessage.replace(/^execution reverted:\s*/, "");
            toastError(modifiedErrorMessage);
            return;
        }
    }
    const claim = async () => {
        settrdone(true);
        try {
            var clm = await stakcontract.claim();
            const receipt = await clm.wait();
            sethash(receipt.transactionHash);
            // sethash(randomHash);
            toastSuccess("Transaction done");
            settrdone(false);
        } catch (error) {
            console.log("Error", error);
            settrdone(false);
            const regex = /"message":"([^"]*)"/g;
            const matches = [...error.message.matchAll(regex)];
            const secondMessage = matches.length >= 2 ? matches[1][1] : null;
            const modifiedErrorMessage = secondMessage.replace(/^execution reverted:\s*/, "");
            toastError(modifiedErrorMessage);
            return;
        }
    }

    const getdata = async () => {
        if (!account) {
            return;
        }
        if (!stakcontract) {
            return;
        }
        var rewarddata = await stakcontract.claims(account);
        var fdata = []
        for (let index = 0; index < rewarddata.length; index++) {
            const element = rewarddata[index];
            var rtbl = {
                id: element._id,
                address: element._address_user,
                token: Number(ethers.utils.formatUnits(element._token, 18)).toFixed(2),
                amt: Number(ethers.utils.formatUnits(element._amt, 18)).toFixed(2),
                date: formatDateTime(element._date),
            }
            fdata.push(rtbl);
        }
        setrewardtbl(fdata);
        console.log("setrewardtbl---------------->", fdata);
    }
    useEffect(() => {
        getdata();
    }, [dbuser, account, stakcontract]);
    useEffect(() => {
        console.log("db Members====>", Members);
    }, [Members, MemebersData]);



    return (
        <>
            <section className="overview-block-ptb ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title">
                                <h2 className="title  iq-mb-20 fw-bold">Dashboard</h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center d-flex flex-wrap justify-content-center">
                                <div className='d-flex justify-content-center mb-5' style={{ width: "350px" }}>
                                    <h6 className='fw-bold'>
                                        {MemebersData ? MemebersData.timer === 0 ? '' :
                                            <CountdownTimer targetDate={MemebersData.timer} /> : ''
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center d-flex flex-wrap justify-content-center">
                                {trdone ?
                                    <img src="/assets/images/loader.gif" alt="LikeME" style={{ width: "100px" }} />
                                    : <>
                                        {
                                            dbuser ?
                                                dbuser.status ?
                                                    dbuser.amount !== 0 ?
                                                        <a type='button' className="button mb-5" onClick={() => claim()}>
                                                            <span className="button-inner-box">
                                                                <span className="icon-one icon">
                                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                                </span>
                                                                <span className="text-btn">Claim</span>
                                                                <span className="icon-two icon">
                                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                                </span>
                                                            </span>
                                                        </a>
                                                        : <a type='button' className="button mb-5" onClick={() => stake()}>
                                                            <span className="button-inner-box">
                                                                <span className="icon-one icon">
                                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                                </span>
                                                                <span className="text-btn">Stake</span>
                                                                <span className="icon-two icon">
                                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                                </span>
                                                            </span>
                                                        </a>
                                                    :
                                                    <a type='button' className="button mb-5" onClick={() => setShow(true)}>
                                                        <span className="button-inner-box">
                                                            <span className="icon-one icon">
                                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                            </span>
                                                            <span className="text-btn">Verify</span>
                                                            <span className="icon-two icon">
                                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                            </span>
                                                        </span>
                                                    </a>
                                                :
                                                <a type='button' className="button mb-5" onClick={() => setShow(true)}>
                                                    <span className="button-inner-box">
                                                        <span className="icon-one icon">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                        </span>
                                                        <span className="text-btn">Verify</span>
                                                        <span className="icon-two icon">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg>
                                                        </span>
                                                    </span>
                                                </a>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="position-relative mb-4 mb-md-0">
                                <div className="pricing ">
                                    <h2 className="mb-2"><sup>$</sup>{dbuser ? dbuser.amount === 0 ? 5 : 0 : 5}</h2>
                                    <p className="mb-0 fs-5 fw-bold text-uppercase">Airdrop</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="position-relative mb-4 mb-md-0">
                                <div className="pricing ">
                                    <h2 className="mb-2"><sup>$</sup>{MemebersData ? MemebersData.stake : 0}</h2>
                                    <p className="mb-0 fs-5 fw-bold text-uppercase">Stake</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="position-relative mb-4 mb-md-0">
                                <div className="pricing ">
                                    <h2 className="mb-2"><sup>$</sup>{MemebersData ? MemebersData.claimd : 0}</h2>
                                    <p className="mb-0 fs-5 fw-bold text-uppercase">$ withdrawal</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="position-relative mb-4 mb-md-0">
                                <div className="pricing ">
                                    <h2 className="mb-2">{MemebersData ? MemebersData.claimt : 0}</h2>
                                    <p className="mb-0 fs-5 fw-bold text-uppercase">LMC Withdrawal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="overview-block-ptb pt-0" style={{ overflowX: "auto" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title">
                                <h2 className="title  iq-mb-20 fw-bold">Reward Claims</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <table className="table" >
                                <thead className="iq-bg">
                                    <tr >
                                        <th scope="col" className='text-white'>#</th>
                                        <th scope="col" className='text-white'>Date</th>
                                        <th scope="col" className='text-white'>Reward($)</th>
                                        <th scope="col" className='text-white'>Reward(LMC)</th>
                                    </tr>
                                </thead>
                                <tr className="text-center">
                                    {isLoading ? "Data is loading" : ""}
                                </tr>
                                {!isLoading ? (
                                    !rewardtbl.length ? (
                                        <tr className="text-center">
                                            <td className="text-center" colSpan={7}>
                                                <span className="w-100">No data found</span>
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    ""
                                )}
                                <tbody>
                                    {rewardtbl.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {ele.date}
                                                </td>
                                                <td>
                                                    {ele.amt}
                                                </td>
                                                <td>
                                                    {ele.token}
                                                </td>

                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                            {/* <nav aria-label="Page navigation example" className='d-flex justify-content-center'>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">«</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">»</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </section>
            <div className={show ? "modal fade show d-block" : "d-none"}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Verify Email Address</h5>
                        </div>
                        <div className="modal-body">
                            <div className="section-field iq-mb-30">
                                <form>
                                    {dbuser ?
                                        <>
                                            <div className="form-group mb-3">
                                                <input type="text"
                                                    className="form-control"
                                                    id="recipient-username"
                                                    placeholder="Enter OTP"
                                                    value={Otp}
                                                    onChange={(e) => {
                                                        const otpValue = e.target.value;
                                                        if (otpValue.length <= 6 && /^\d+$/.test(otpValue)) {
                                                            setOtp(otpValue);
                                                        } else if (otpValue.length < 6) {
                                                            setOtp(otpValue); // allow deleting characters
                                                        }
                                                    }}
                                                    maxLength={6}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <Link type='button' onClick={() => editEmail()}>Edit Email <i className='fa fa-edit'></i></Link>
                                                <Link type='button' onClick={() => resendEmail()}>Resend OTP <i className='fa fa-refresh'></i></Link>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <button type='button' className="button iq-mtb-10" onClick={() => checkOtp()}>Verify</button>
                                            </div>
                                        </> :
                                        <>
                                            <div className="form-group mb-3">
                                                <input type="email" className="form-control" id="recipient-username" placeholder="Enter Email Address" value={email}
                                                    onChange={(e) =>
                                                        setemail(e.target.value)
                                                    } />
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" checked={tandc} onChange={(event) => settandc(event.target.checked)} />I Agree to the Terms and Conditions</label>
                                            </div>

                                            <div className='d-flex justify-content-center'>
                                                <button type='button' className="button iq-mtb-10" onClick={() => onDeposit()}>Send OTP</button>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => onDeposit()}>Save changes</button>
                        </div> */}
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className={show ? "modal-backdrop fade show" : "d-none"}></div>
        </>
    )
}
