import React, { useContext } from 'react'
import { ProjectContext } from '../../ProjectContext';

export default function Header() {
    const { account, connectMetamask, disconnectNow, dbuser, copyaddress, formatAddress } = useContext(ProjectContext);

    return (
        <>
            <header>
                <div className="topbar-chart">
                    <div classname="container-fluid pb-0">
                        <p className='text-center blink_me mb-0 fw-bold'><a href="" style={{ color: "#e31e24" }}>LikeME Airdrop - $50,000 Prize Pool | Claim Free LMC Tokens  [ Join Now ]</a></p>
                    </div>
                </div>

                <div className="iq-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                                <div className="logo my-3">
                                    <a href="/"><img id="logo_dark" className="img-fluid w-100 h-100" src="assets/img/logo.svg" alt="logo" /></a>
                                </div>
                                <nav className=''>
                                    {account ?
                                        <>
                                            <a className="button mt-3" onClick={connectMetamask}>
                                                {formatAddress(account)}
                                            </a>
                                            <a className="button mt-3" href='/dashboard'>
                                                <i className='fa fa-user' />
                                            </a>
                                        </>
                                        :
                                        <a className="button mt-3" onClick={connectMetamask}>
                                            <span className="button-inner-box">
                                                <span className="icon-one icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                                <span className="text-btn">Connect</span>
                                                <span className="icon-two icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" /></svg></span>
                                            </span>
                                        </a>
                                    }
                                    {/* <a className="button mt-3" href="javascript:void(0)">
                                        <i className='fa fa-wallet text-white' />
                                    </a> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}
