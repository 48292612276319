import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import About from './About'
import Steps from './Steps'
import Fetures from './Fetures'

export default function Home() {
    return (
        <>
            <Header />
            <Hero />
            <div className="main-content">
                <About />
                <Steps/>
                <Fetures/>
            </div>
            <Footer />
        </>
    )
}
