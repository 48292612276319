import React, { useEffect, useState } from 'react'
import DashboardHero from './DashboardHero'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Statistics from './Statistics'
export default function Dashboard() {

    const confettiNum = 400;
    const coinNum = 30;
    const dollarNum = 50;
    const animationDuration = 10; // in seconds

    const [isAnimating, setIsAnimating] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const generateElements = () => {
        const elements = [];

        // Generate dollar elements
        for (let i = 0; i < dollarNum; i++) {
            elements.push(<div key={`dollar-${i}`} className={`dollar-${i}`}></div>);
        }

        // Generate coin elements
        for (let i = 0; i < coinNum; i++) {
            elements.push(<div key={`coin-${i}`} className={`coin-${i}`}></div>);
        }

        // Generate confetti elements
        for (let i = 0; i < confettiNum; i++) {
            elements.push(<div key={`confetti-${i}`} className={`confetti-${i}`}></div>);
        }

        return elements;
    };

    useEffect(() => {
        let animationTimer;
        if (isAnimating) {
            // Start animation
            setIsVisible(true);
            animationTimer = setTimeout(() => {
                setIsAnimating(false); // Stop animation after animationDuration
                setIsVisible(false); // Hide animation elements
            }, animationDuration * 1000);
        }

        return () => {
            clearTimeout(animationTimer); // Cleanup timer on component unmount or state change
        };
    }, [isAnimating]);

    const handleStartAnimation = () => {
        setIsAnimating(true); // Set state to start animation
    };
    return (
        <>
            <div className={`confettiHerePlease dollarDollarBillYall moreMoneyMoreProblems ${isAnimating ? 'animate' : ''}`} style={{ display: isVisible ? 'block' : 'none' }}>
                {generateElements()}
            </div>
            <Header />
            {/* <DashboardHero /> */}
            <div className="main-content">
                <Statistics generateElements={handleStartAnimation} />
            </div>
            <Footer />
        </>
    )
}
