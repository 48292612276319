import React from 'react'

export default function Fetures() {
    return (
        <>
            <div>
                a <section className="overview-block-ptb iq-fea-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title  iq-mb-25">Why Join LikeME
                                    </h2>
                                    <p>LIkeME brings you an innovative social media platform that is decentralized, free & open for all. It represents a bonding-curve in the social media landscape where most of the applications are censored & controlled by one entity. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row h-100">
                            <div className="col-lg-4 col-md-12 text-end">
                                <div className="d-flex align-items-center">
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href=""> Post-to-earn Approach
                                        </a></h4>
                                        <p>Users would be able to earn Tokens by posting content & benefit with project’s novel token economy. 
                                        </p>
                                    </div>
                                    <div className="ms-3">
                                        <img className="img-fluid" src="assets/img/post_to_earn_approach-8.png" alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href=""> Secure at Core</a></h4>
                                        <p>LikeME is highly secure as it inherits security features of blockchain technology & immutable smart contracts. 
                                        </p>
                                    </div>
                                    <div className="ms-3">
                                        <img className="img-fluid" src="assets/img/secure_at_core-8.png" alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href=""> Improved UX
                                        </a></h4>
                                        <p>Features & functionalities of LikeME are upscaled with the aim to provide users a friendly social platform. </p>
                                    </div>
                                    <div className="ms-3">
                                        <img className="img-fluid" src="assets/img/improved_UX-8.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 align-self-center text-center">
                                <img className="img-fluid" src="assets/img/why_join_likeme_img.png" alt="" />
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <img className="img-fluid" src="assets/img/easy_accessibility-8.png" alt="" />
                                    </div>
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href="">Easy Accessibility
                                        </a></h4>
                                        <p>LikeME application is accessible on any web browser as well as on iOS and Android mobile devices. </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <img className="img-fluid" src="assets/img/decentralized-8.png" alt="" />
                                    </div>
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href="">Decentralized</a></h4>
                                        <p>The control of LikeME is decentralized hence it can not be controlled by any entity nor the user data is shared with anyone. 
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <img className="img-fluid" src="assets/img/censorship_resistance-8.png" alt="" />
                                    </div>
                                    <div className="iq-feature2 iq-mtb-20">
                                        <h4 className="mb-3"><a href="">Censorship Resistance</a></h4>
                                        <p>Content policy on LikeME does not include any specific restriction on users, they can post whatever they want. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="particles text-center"><img className="img-fluid" src="assets/revslider/particles.png" alt="" /></div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}
