import React from 'react'

export default function Steps() {
    return (
        <>
            <section className="overview-block-ptb125 iq-feature4 iq-bg iq-over-black-80 jarallax" style={{ backgroundImage: 'url("assets/revslider/bg/bg-5.png")', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="iq-Work-box text-center text-white stap-up">
                                <div className="Work-icon">
                                    <div className="step">1</div>
                                    <img className="img-fluid ion-ios-compose-outline" src="assets/img/go_to_airdrop.png" alt="" />
                                </div>
                                <h4 className="iq-mt-20 iq-font-yellow mb-3">Go To Airdrop</h4>
                                <p>Participate in Airdrop by joining & connecting to the LikeME app.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 re-mt-30">
                            <div className="iq-Work-box text-center text-white stap-dowen">
                                <div className="Work-icon">
                                    <div className="step">2</div>   
                                    <img className="img-fluid ion-ios-compose-outline" src="assets/img/complete_tasks.png" alt="" />
                                </div>
                                <h4 className=" iq-mt-20 iq-font-yellow mb-3">Complete Tasks
                                </h4>
                                <p>Complete all listed tasks by joining our social channels & filling up required information. </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 re-mt-30">
                            <div className="iq-Work-box text-center text-white">
                                <div className="Work-icon">
                                    <div className="step">3</div>
                                    <img className="img-fluid ion-ios-compose-outline" src="assets/img/claim_free_tokens.png" alt="" />
                                </div>
                                <h4 className=" iq-mt-20 iq-font-yellow mb-3">Claim Free Tokens
                                </h4>
                                <p>On completing tasks successfully, you will be able to claim free LMC tokens. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
